import React from 'react'
import CaseStudyLayout, {
  Quote,
  StarDivider,
  DotsDivider,
  SliderDivider,
  CustomerPhoto,
  EdroneHero,
  OtherCaseStudies,
} from '../../components/casestudy/caseStudyLayout'
import Seo from '../../components/seo'

import shareImage from '../../components/casestudy/images/edroneShare.png'

const Edrone = () => {
  return (
    <CaseStudyLayout
      title="How Edrone uses Probe to replace its in-house BI"
      hero={<EdroneHero />}
    >
      <Seo
        title="Probe for Edrone - case study"
        description={'Edrone uses Probe to replace its in-house BI'}
        keywords={[
          'business analytics',
          'edrone.me',
          'saas',
          'excel',
          'spreadsheets',
          'bi',
          'analytics',
        ]}
        twitterImg={shareImage}
        mainpageImg={shareImage}
      />
      <CustomerPhoto
        name="Lukasz Chwistek"
        title="Project & Operations Manager"
        company="Edrone"
        webPage="https://edrone.me/en/"
      />
      <div className="py-5">
        <h2>What do you do at Edrone?</h2>
        <p>Project & Operations Manager, reporting directly to CEO.</p>
      </div>
      <StarDivider />
      <div className="py-5">
        <h2>What’s the problem that Probe is solving for you?</h2>
        <p>
          What is not measured, cannot be managed. It is worth emphasizing two
          key management levels on which Probe provides high value.
        </p>
      </div>
      <DotsDivider />
      <div className="py-5">
        <p>
          Firstly, perhaps you are aware that in order to grow as a SaaS
          business, you need to acquire new customers and - to counteract churn
          - take proper care of those who have already joined. Over time, as the
          number of clients increases significantly, it becomes crucial to
          identify their movements. New MRR combined with expansion, renewal,
          contraction and churn sheds detailed light on how your business is
          growing or - which may seem unobvious despite the high new sales level
          - does not grow as expected.
        </p>
      </div>
      <DotsDivider />
      <div className="py-5">
        <p>
          Secondly, Probe broadens your SaaS KPIs knowledge. New MRR net &
          gross, Churn net & gross, Churn & Retention cohorts, LTV, LTV/CAC or
          Quick Ratio are no longer a theory. They are part of your business,
          identified and analyzed over time.
        </p>
      </div>
      <Quote text="The better you understand key metrics the greater the value and monetization of your business." />
      <StarDivider />
      <div className="py-5">
        <h2>
          Which other solutions have you tried before Probe? Why did they fail?
        </h2>
        <p>
          ERP enova365 collects all financial data, while not giving any space
          for key C-Level feedback from the SaaS business model point of view.
          Along with the increase in the number of customers and the expansion
          of the product offer.
        </p>
      </div>
      <Quote text="Our in-house BI solution lost its effectiveness and required too much commitment in relation to the results." />
      <div className="py-5">
        <h2>What was important for you when trying to solve this problem?</h2>
        <p>
          Speed, availability, clarity and flexibility of information sought.
        </p>
      </div>
      <StarDivider />
      <div className="py-5">
        <h2>What did the implementation process look like?</h2>
        <p>
          Discovery call, data import, monthly report - all crowned with ongoing
          success. These four checkpoints allowed me to catch the outline of the
          process. But the process itself is in-depth - includes as many
          iterations as required to let you feel that you trust your data in
          Probe. No rush, being convinced is paramount for Probe.
        </p>
      </div>
      <StarDivider />
      <div className="py-5">
        <h2>
          How did it change after adopting Probe? Did it become easier? Faster?
          Do you have any numbers to back this up?
        </h2>
        <p>
          Ensured on-demand access to financial data and a series of SaaS
          metrics.
        </p>
      </div>
      <Quote text="With Probe Every C-Level Manager is able to prepare their own individual data set to focus deeply on what is relevant to their departments. ETA? In the blink of an eye."></Quote>
      <div className="py-5">
        <h2>Anything else you wanna add?</h2>
      </div>
      <Quote text="Attitude towards clients - top level support!"></Quote>
      <SliderDivider />
      <div className="pb-5"></div>
      <OtherCaseStudies names={['castle', 'callpage', 'devskiller']} />
    </CaseStudyLayout>
  )
}

export default Edrone
